<template>
  <div>
    <el-dialog title="选择教师"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="800px"
               :before-close="handleClose">
      <p>选择教师</p>
      <el-input placeholder="请输入名称"
                style="width:200px"
                v-model="keyword" />
      <el-button type="primary"
                 style="margin-left:10px"
                 @click="search">
        搜索
      </el-button>
      <el-table :data="list"
                :max-height="$store.state.tableHeight / 3"
                :row-key="getRowKeys"
                style="width:100%">
        <el-table-column width="55">
          <template slot-scope="scope">
            <div class="select"
                 @click="checkRow(scope.row,scope.$index)">
              <i v-if="list1.find(item => item.edu_user_id == scope.row.edu_user_id)"
                 class="el-icon-check"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="edu_user_id"
                         label="教师ID" />
        <el-table-column prop="edu_user_name"
                         label="教师名称" />

      </el-table>
      <pagination ref="pagination"
                  v-if="dialogVisible"
                  :get-data="initData"
                  :now-page.sync="page"
                  :now-size.sync="size"
                  :total="total" />
      <p>已选教师</p>
      <el-table :data="list1"
                style="width:100%">
        <el-table-column prop="edu_user_id"
                         label="教师ID" />
        <el-table-column prop="edu_user_name"
                         label="教师名称" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="text_btn_danger"
                 @click="delItem(scope.$index)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <el-button @click='handleAt'
                 type='primary'>确定</el-button>

    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      list: [],
      list1: [],
      allData: [],
      page: 1,
      size: 10,
      total: 0,
      keyword: '',
    }
  },
  methods: {
    search () {
      this.page = 1
      this.initData()
    },
    handleSelectionChange (row) {
      this.list1.push(row)
    },
    getRowKeys (row) {
      return row.edu_user_id
    },
    // 取消
    handleClose () {
      let arr = JSON.parse(JSON.stringify(this.list1))
      this.dialogVisible = false
      this.list1 = []
      this.list1.splice(0, this.list1.length)
    },
    // 确认
    handleAt () {
      let arr = JSON.parse(JSON.stringify(this.list1))
      this.$parent.setData(arr)
      this.dialogVisible = false

    },
    initData () {
      var start = this.size * (this.page - 1)
      var end = this.size * this.page
      if (this.keyword != '') {
        let list = this.allData.filter(item => item.edu_user_name.includes(this.keyword))
        this.list = list.slice(start, end)
        return
      }
      this.list = this.allData.slice(start, end)
    },
    delItem (index) {
      this.list1.splice(index, 1)
    },
    // 获取总列表的框框是否选中
    getShow (row) {
      var one = this.list1.find(item => item.edu_user_id == row.edu_user_id)
      if (one) {
        return true
      } else {
        return false
      }
    },
    // 点击自定义选择框，如果已选择，取消
    checkRow (row) {
      if (this.list1.find(item => item.edu_user_id == row.edu_user_id)) {
        let index = this.list1.findIndex(item => item.edu_user_id == row.edu_user_id)
        this.list1.splice(index, 1)
      } else {
        this.list1.push(row)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
.select {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdfe6;
  cursor: pointer;
  color: #449cf5;
  img {
    width: 10px;
    height: 10px;
    .el-icon-check {
      color: #409eff;
    }
  }
}

p {
  font-size: 16px;
  font-weight: bold;
}
</style>